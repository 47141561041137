var OBSERVABLE_ELEMENTS                 =   {};
var OBSERVABLE_CONTENT                  =   new WeakMap();

export const FINBEE_OBSERVABLE_EVENT    =   "finbeeObservableEvent";

/**
 * do send observable event to <form> parent?
 */
const SUPPORT_FORM_TAG                  =   true;

/**
 * registers the element selector into observable list
 */
export function ObservableElement_Add( selector, pfn ){
    OBSERVABLE_ELEMENTS[ selector ]     =   pfn;
    console.debug( 'ObservableElement_Add : registered observable for ', selector, 'with', pfn );
    return                              true;
}

export function ObservableElement_SetRegistry( registry ){
    OBSERVABLE_ELEMENTS                 =   {};
    if  ( Array.isArray( registry ) ){
        registry.forEach( ( [ selector, pfn ] ) => {
            OBSERVABLE_ELEMENTS[ selector ] =   pfn;
            console.debug( 'ObservableElement_SetRegistry : registry init with', selector, pfn );
        } );
    }
}

/**
 * root function to bind all elements
 */
export function ObservableElement_InitListeners(){
    let observableLength            =   undefined;
    if  ( observableLength = Object.entries( OBSERVABLE_ELEMENTS ).length ){
        console.debug( `Observable : found ${observableLength} observable elements` );
    } else {
        return;
    }

    function triggerObservablePerformed( element, isConstructed ){
        let event                   =   new CustomEvent( FINBEE_OBSERVABLE_EVENT, {
            detail                          :   {
                elementCalled                   :   element,
                isConstructed                   :   isConstructed,
            },
            bubbles                         :   true,
            cancelable                      :   true
        } );
        element.dispatchEvent( event );
        // if  ( SUPPORT_FORM_TAG ){
        //     let closestForm             =   element.closest( "form" );
        //     if  ( closestForm ){
        //         closestForm.dispatchEvent( event );
        //         return;
        //     }
        // } 
        // document.dispatchEvent( event );
    }

    ( new MutationObserver( ( mutations ) => {
        let insertion                   =   mutations.reduce( ( nodes, record ) => { 
            return [ ...nodes, ...( Array.from( record.addedNodes ).filter( element => element.nodeType === 1 ) ) ]; }, [] 
        );

        insertion.forEach( ( rootElement ) => {
            Object.entries( OBSERVABLE_ELEMENTS ).forEach( ( [ selector, pfn ] ) => {
                if  ( rootElement.matches( selector ) ){
                    pfn( rootElement );
                    triggerObservablePerformed( rootElement, true );
                    console.debug( "ObservableElement_InitListeners : matched root element", rootElement, 'with', pfn );
                } else {
                    rootElement.querySelectorAll( selector ).forEach( ( subElement ) => {
                        pfn( subElement );
                        triggerObservablePerformed( subElement, true );
                        console.debug( "ObservableElement_InitListeners : matched sub element", subElement, 'with', pfn );
                    } );
                }
            } );
        } );
    } ) ).observe( document, { childList : true, subtree : true } );

    Object.entries( OBSERVABLE_ELEMENTS ).forEach( ( [ selector, pfn ] ) => {
        document.querySelectorAll( selector ).forEach( ( element ) => {
            pfn( element );
            triggerObservablePerformed( element, true );
            console.debug( 'ObservableElement_InitListeners : registered', element, 'with', pfn );
        } );
    } );

    window.getObservableRegistry = () => OBSERVABLE_ELEMENTS;

    console.debug( 'Observable start performed!' );
}

export function ObservableElement_AddToExisting( element, attachedData ){
    console.debug( "ObservableElement_AddToExisting : registered", element, "with", attachedData );
    OBSERVABLE_CONTENT.set( element, attachedData );
}

export function ObservableElement_ListExisting(){
    return                              OBSERVABLE_CONTENT;
}

/**
 * Check if this {element} have {parent} as one of its parents
 * 
 * @param {HTMLElement} element 
 * @param {HTMLElement} parent 
 */
export function checkParentIs( element, parent ){
    if  ( !element || !parent ){
        return                          false;
    }
    let prev                            =   element.parentElement;
    while   ( prev !== null ){
        if  ( prev === parent ){
            return                      true;
        } else {
            prev                        =   prev.parentElement;
        }
    }
    return                              false;
}