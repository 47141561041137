/**
 * Responsible for smulaing mutex behaviour for proper load order 
 */

export const LAST_MUTEX_ORDER           =   32768;

export function MutexView_InitStorage(){
    window[ "MutexViewStorage" ]        =   [];
}

export function MutexView_InsertClass( className, fn, order = 1 ){
    window[ "MutexViewStorage" ].push( [ className, fn, order ] );
}

export function MutexView_Run(){
    console.debug( `MutexView_Run : planning to execute ${window[ "MutexViewStorage" ].length} mutexes` );
    window[ "MutexViewStorage"].sort( ( a, b ) => { return a[ 2 ] > b[ 2 ] ? 1 : -1 } );
    window[ "MutexViewStorage" ].forEach( ( [ className, fn, order ] ) => {
        fn();
        console.debug( "MutexView_Run : called for className", className, "with order", order );
    } );
    // window.dispatchEvent( new Event( "MutexView_MutexReady" ) );
}