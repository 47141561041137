export const enum TDebugLevel{ 
    DEBUG,
    ERROR,
    WARNING,
    INFO
};

let debug : TDebugLevel         =   TDebugLevel.DEBUG;

/**
 * sets a global application debug level
 */
export function d_set_level( level : TDebugLevel ){
    debug                       =   level;
    window.DEBUG_LEVEL          =   debug;
    if  ( debug === TDebugLevel.DEBUG ){
        console.debug( "application is now on DEBUG mode" );
    }
}

/**
 * gets a global application debug level
 */
export function d_get_level() : TDebugLevel{
    return                      debug;
}

export function d_message( level : TDebugLevel, ...args : any[] ){
    if ( level < debug )
        return;
    switch  ( level ){
        case    TDebugLevel.DEBUG:
            console.debug( ...args );
            break;
        case    TDebugLevel.WARNING:
            console.warn( ...args );
            break;
        case    TDebugLevel.ERROR:
            console.error( ...args );
            break;
    }
}

export function d_class_message( c : string, m : string, ...args : any[] ) : Array<string>{
    return                      [].concat( `${c}::${m}`, ...args );
}

