import { TDebugLevel, d_get_level } from "./debugTools";

export type TInstantiablePropertyAccessor = ( propName : string, value? : string, ...args : any[] ) => HTMLElement|null|any;

export type TInstantiablePropertyList = {
    [ name : string ] : TInstantiablePropertyAccessor|Array<Function|any>|String|null
};

export abstract class AbstractInstantiableProperties{

    protected _root : HTMLElement;

    protected _viewId : string|null;

    protected _showDebugData : boolean|undefined;

    get viewId() : string|null{
        return                          this._viewId;
    }

    get showDebugData() : boolean|undefined{
        return                          this._showDebugData;
    }

    get root() : HTMLElement{
        return                          this._root;
    }

    get rootClass(){
        return                          HTMLElement;
    }

    protected __getClassName() : String{
        return                          ( <any>this ).constructor.name;
    }

    protected _getRoot() : HTMLElement{
        return                          this._root;
    }

    protected _getRootClass(){
        return                          HTMLElement;
    }

    //#region  message functions

    __prepareMessage( ...args ) : string|Array<string|any>{
        if  ( args.length > 1 ){
            return                      [].concat( [ `${this.viewId ?? this.constructor.name}::${args[ 0 ] } : ` ], args.slice( 1 )  );
        } else {
            return                      args;
        }
    }
    
    /**
     * first arg must be a proc name
     */
    __debugMessage( ...args ){
        if  ( this.showDebugData ){
            console.debug.call( this, ...this.__prepareMessage.call( this, ...args ) );
        }
    }

    __errorMessage( ...args ){
        if  ( this.showDebugData ){
            console.error.call( this, ...this.__prepareMessage.call( this, ...args ) );
        }
    }

    __createException( ...args ){
        return                          new Error( <string>this.__prepareMessage( ...args ) );
    }

    //#endregion

    constructor( element : HTMLElement ){
        // auto set debug application level if there is undefined
        if  ( this._showDebugData === undefined ){
            this._showDebugData         =   d_get_level() === TDebugLevel.DEBUG; 
        }
        if  ( !element ){
            throw                       this.__createException( '__constructor', 'no root element' );
        }
        if  ( element instanceof HTMLElement ){
            this._root                  =   element;
        } else { 
            this._root                  =   <HTMLElement><unknown>document.querySelector( element );
        }
        if  ( !this._afterConstruction() ){
            throw                       this.__createException( '__constructor', '_afterConstruction failed' );
        }
    }

    /**
     * Redefine/extend to autowire more properties
     */
    protected __getInstantiablePropertiesList() : TInstantiablePropertyList|null{
        return                          null;
    }

    protected __initInstantiableProperties() : boolean{
        let instantiable                =   this.__getInstantiablePropertiesList();
        if  ( typeof instantiable !== 'object' ){
            return                      false;
        }
        let root                        =   this._getRoot();
        let rootClass                   =   this._getRootClass();
        if  ( !( root instanceof rootClass ) ){
            throw                       this.__createException( '__initInstantiableProperties', `root must be an instance of ${rootClass}` );
        }
        Object.entries( instantiable ).forEach( ( [ property, resolver ] ) => {
            let func                    =   null;
            let args                    =   [
                property
            ];
            let exists                  =   root.dataset.hasOwnProperty( property );
            if  ( Array.isArray( resolver ) ){
                if  ( typeof ( func = (  resolver[ 0 ] ?? undefined ) ) !== 'function' ){
                    this.__errorMessage( '__initInstantiableProperties', 'Array resolver expects [ 0 ] to be a function' );
                    exists              =   undefined;
                } else {
                    args                =   args.concat( 
                        [ exists ? root.dataset[ property ] : undefined ],
                        resolver.slice( 1 ) 
                    );
                }
            } else if ( typeof resolver === 'function' ) {
                func                    =   resolver;
                args                    =   args.concat(
                    [ exists ? root.dataset[ property ] : undefined ]
                );
            } else {
                args                    =   exists ? root.dataset[ property ] : ( <any>resolver );
            }
            if  ( typeof exists !== 'undefined' ){
                this[ '_' + property ]  =   func ? ( <Function>func ).call( null, ...args ) : args;
                if  ( exists ){
                    delete              root.dataset[ property ];
                }
                this.__debugMessage( '__initInstantiableProperties', `property "${property}" value is`, this[ '_' + property ] );
            }
        } );
        // Object.entries( instantiable ).forEach( ( [ property, fn ] ) => {
        //     if  ( root.dataset.hasOwnProperty( property ) ){
        //         this.__debugMessage( '__initInstantiableProperties', `got property "${property}" with value "${root.dataset[ property ]}"` );
        //         if  ( ( typeof fn === 'function' ) || ( Array.isArray( fn ) ) ){
        //             let isFnArray       =   Array.isArray( fn );
        //             if  ( isFnArray && typeof fn[ 0 ] === 'function' ){
        //                 /** 
        //                  * calls a method of signature ( propertyName, value, ...args )
        //                  * args are all next elements except of first
        //                  */
        //                 this[ '_' + property ]  =   fn[ 0 ].call( null, ...[ property, root.dataset[ property ], ...( <Array<any>>fn ).slice( 1 ) ] );
        //             } else if ( !isFnArray ) {
        //                 this[ '_' + property ]  =   ( <TInstantiablePropertyAccessor>fn )( property, root.dataset[ property ], null );
        //             } else{
        //                 this.__debugMessage( '__initInstantiableProperties', `wrong resolver for property "${property}"` );
        //                 this[ '_' + property ]  =   undefined;
        //             }
        //         } else {
        //             this[ '_' + property ]  =   root.dataset[ property ];
        //         }
        //         delete root.dataset[ property ];
        //         this.__debugMessage( '__initInstantiableProperties', `property "${property}" value is`, this[ '_' + property ] );
        //     } else {
        //         this.__debugMessage( '__initInstantiableProperties', `property "${property}" is unregistered, setting default value` );
        //         if  ( typeof fn === 'function' ){
        //             this[ '_' + property ]  =   fn( property );
        //         } else {
        //             this[ '_' + property ]  =   fn;
        //         }
        //         this.__debugMessage( '__initInstantiableProperties', `property "${property}" value is`, this[ '_' + property ] );
        //     }
        // } );
        return                          true;
    }

    protected __debugListInstantiableValues(){
        let instantiable                =   this.__getInstantiablePropertiesList();
        if  ( ( typeof instantiable !== 'object' ) || !this.showDebugData ){
            return;
        }
        Object.keys( instantiable ).forEach( ( k ) => {
            console.debug.call( this, ...this.__prepareMessage( '__debugListInstantiableValues', `${k} is`, this[ "_" + k ] ) ); 
        } );
    }

    /**
     * Call this internally to produce some info to root
     */
    protected _produceWindowMessage( msg : string, stage : string = undefined , data : any = undefined, pfnDefault : Function|undefined|null = undefined ){
        let event                       =   new CustomEvent( msg, {
            detail : {
                "stage"                 :   stage,
                "data"                  :   data,
                "ret"                   :   undefined
            },
            bubbles                     :   true,
            cancelable                  :   true
        } );
        if  ( !this._root.dispatchEvent( event ) && typeof pfnDefault === 'function' ){
            pfnDefault( event );
        }
    }

    protected addEventListener( event, fn, opts = undefined ){
        this._root.addEventListener( event, fn, opts );
    }

    protected _afterConstruction() : boolean{
        return                          this.__initInstantiableProperties();
    }

}