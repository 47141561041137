function kebabCase( str ){
    return                              str &&
        str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join('-');
}

export function __convertDatasetValueToBoolean( propName, value ){
    let isTrue                          =   ( value === true || value === "1" );
    if  ( !isTrue ){
        isTrue                          =   ( "data-" + kebabCase( propName ) ) === value;
    }
    return                              isTrue;
}

export function __convertDatasetValueToInteger( propName, value ){
    return                              isNaN( Number( value ) ) ? undefined : Number( value );
}

export function __convertDatasetValueToJSON( propName, value ){ 
    let converted                   =   null;
    try {
        converted                   =   JSON.parse( value ); 
    } catch ( e ) {
        converted                   =   null; 
    } 
    return                          converted;
}

export function __convertDatasetValueToElement( propName, value, data ){
    let result                      =   ( typeof value === 'string' ) ? document.querySelector( ( value[ 0 ] != "#" && value[ 0 ] != "." ) ? "#" + value : value ) : null;
    if  ( result && data ){
        result                      =   $( result ).data( data );
    }
    return                          result;
}

export function __convertDatasetValueWithDefault( propName, value, def ){
    return                          value ?? def;
}

export const MSG_AIPC               =   "finbeeAIPCMessage";

import { AbstractInstantiableProperties } from "../next/AbstractInstantiableProperties";

export const AbstractInstantiablePropertiesClass = AbstractInstantiableProperties;

// rename it to commented line below and remove last imports to use etalon
// export class AbstractInstantiablePropertiesClass{
class AbstractInstantiablePropertiesClassEtalon{

    __getClassName(){
        return                          this.constructor.name;
    }

    _getRoot(){
        return                          this._root;
    }

    _getRootClass(){
        return                          HTMLElement;
    }

    _getViewId(){
        return                          this?._viewId ?? this.__getClassName();
    }

    __getShowDebugData(){
        return                          false;
    }

    get showDebugData(){
        return                          this?.__getShowDebugData();
    }

    __prepareMessage( ...args ){
        if  ( args.length > 1 ){
            return                      [].concat( [ `${this._getViewId()}::${args[ 0 ] } : ` ], args.slice( 1 )  );
        } else {
            return                      args;
        }
    }
    
    /**
     * first arg must be a proc name
     */
    __debugMessage( ...args ){
        if  ( this.showDebugData ){
            console.debug.call( this, ...this.__prepareMessage.call( this, ...args ) );
        }
    }

    __errorMessage( ...args ){
        if  ( this.showDebugData ){
            console.error.call( this, ...this.__prepareMessage.call( this, ...args ) );
        }
    }

    __createException( ...args ){
        return                          new Error( this.__prepareMessage( ...args ) );
    }

    /**
     * Redefine/extend to autowire more properties
     * 
     * @returns [{string,function( string )}]
     */
    __getInstantiablePropertiesList(){
        return                          null;
    }

    __initInstantiableProperties(){
        let instantiable                =   this.__getInstantiablePropertiesList();
        if  ( typeof instantiable !== 'object' ){
            return                      false;
        }
        let root                        =   this._getRoot();
        let rootClass                   =   this._getRootClass();
        if  ( !( root instanceof rootClass ) ){
            throw                       this.__createException( '__initInstantiableProperties', `root must be an instance of ${rootClass}` );
        }
        Object.entries( instantiable ).forEach( ( [ property, fn ] ) => {
            if  ( root.dataset.hasOwnProperty( property ) ){
                this.__debugMessage( '__initInstantiableProperties', `got property "${property}" with value "${root.dataset[ property] }"` );
                if  ( ( typeof fn === 'function' ) || ( Array.isArray( fn ) ) ){
                    let isFnArray       =   Array.isArray( fn );
                    if  ( isFnArray && typeof fn[ 0 ] === 'function' ){
                        this[ '_' + property ]  =   fn[ 0 ].call( null, ...[ property, root.dataset[ property ], ...fn.slice( 1 ) ] );
                    } else if ( !isFnArray ) {
                        this[ '_' + property ]  =   fn( property, root.dataset[ property ] );
                    } else{
                        this.__debugMessage( '_afterConstruction', `wrong resolver for property "${property}"` );
                        this[ '_' + property ]  =   undefined;
                    }
                } else {
                    this[ '_' + property ]  =   root.dataset[ property ];
                }
                delete root.dataset[ property ];
                this.__debugMessage( '_afterConstruction', `property "${property}" value is`, this[ '_' + property ] );
            } else {
                this.__debugMessage( '_afterConstruction', `property "${property}" is unregistered, setting default value` );
                if  ( typeof fn === 'function' ){
                    this[ '_' + property ]  =   fn( property );
                } else {
                    this[ '_' + property ]  =   fn;
                }
                this.__debugMessage( '_afterConstruction', `property "${property}" value is`, this[ '_' + property ] );
            }
        } );
        return                          true;
    }

    __debugListInstantiableValues(){
        let instantiable                =   this.__getInstantiablePropertiesList();
        if  ( ( typeof instantiable !== 'object' ) || !this.showDebugData ){
            return;
        }
        Object.keys( instantiable ).forEach( ( k ) => {
            console.debug.call( this, ...this.__prepareMessage( 'debugListInstantiableValues', `${k} is`, this[ "_" + k ] ) ); 
        } );
    }

    _afterConstruction(){
        return                          this.__initInstantiableProperties();
    }

    /**
     * Call this internally to produce some info to root
     */
    _produceWindowMessage( msg, stage = undefined , data = undefined, pfnDefault = undefined ){
        let event                       =   new CustomEvent( msg, {
            detail : {
                "stage"                 :   stage,
                "data"                  :   data,
                "ret"                   :   undefined
            },
            bubbles                     :   true,
            cancelable                  :   true
        } );
        if  ( !this._root.dispatchEvent( event ) && typeof pfnDefault === 'function' ){
            pfnDefault( event );
        }
    }

    addEventListener( event, fn, opts = undefined ){
        this._root.addEventListener( event, fn, opts );
    }

    constructor( element ){
        if  ( !element ){
            throw                       this.__createException( '__constructor', 'no root element' );
        }
        if  ( element instanceof HTMLElement ){
            this._root                  =   element;
        } else { 
            this._root                  =   document.querySelector( element );
        }
        if  ( !this._afterConstruction() ){
            throw                       this.__createException( '__constructor', '_afterConstruction failed' );
        }
    }

}