import { AbstractInstantiablePropertiesClass, __convertDatasetValueToBoolean } from "../../common/AbstractInstantiablePropertiesClass";
import { API_QUERY_MODE_SEARCH, PayloadBuilder_MakePayload, PayloadBuilder_SubmitPayload } from "../../common/payloadBuilder";


const LAZY_SELECTOR                     =   ".js-lazy--listen";
const LAZY_INITIALIZED_CLASS            =   "js-lazy--initialized";

export const FINBEE_FORM_EVENT          =   "finbeeFormEvent";
export const FINBEE_FORM_STAGE_GOOD     =   true;
export const FINBEE_FORM_STAGE_BAD      =   false;

export class AbstractForm extends AbstractInstantiablePropertiesClass{

    get onFormSubmitBefore(){
        return                          this?._pfnOnFormSubmitBefore;
    }

    set onFormSubmitBefore( arg ){
        this._pfnOnFormSubmitBefore     =   ( typeof arg === 'function' ) ? arg : null;
    }

    get onFormSubmitSuccess(){
        return                          this?._pfnOnFormSubmitSuccess;
    }

    set onFormSubmitSuccess( arg ){
        this._pfnOnFormSubmitSuccess    =   ( typeof arg === 'function' ) ? arg : null;
    }

    get onFormSubmitFail(){
        return                          this?._pfnOnFormSubmitFail;
    }

    set onFormSubmitFail( arg ){
        this._pfnOnFormSubmitFail       =   ( typeof arg === 'function' ) ? arg : null;
    }

    get onFormSubmitAlways(){
        return                          this?._pfnOnFormSubmitAlways;
    }

    set onFormSubmitAlways( arg ){
        this._pfnOnFormSubmitAlways     =   ( typeof arg === 'function' ) ? arg : null;
    }

    /**
     * must we wait lazy elements to be initialized before putting logic on?
     */
    __hasLazyListener(){
        return                          false;
    }

    __enableCustomPayloadBuild(){
        return                          false;
    }

    __getDefaultQueryMode(){
        return                          API_QUERY_MODE_SEARCH;
    }

    /**
     * extends a jquery-serializer to save autoNumeric values
     * @returns 
     */
    __extendSerializerCustomTypes(){
        return                          {
            customNumeric               :   ( val, element ) => {
                if  ( typeof autoNumericGlobalList !== 'undefined' ){
                    if  ( autoNumericGlobalList.has( element ) ){
                        let autoNumericInstance =   autoNumericGlobalList.get( element );
                        return          ( autoNumericInstance ) ? autoNumericInstance.rawValue : val ;
                    } else {
                        return          val;
                    }
                }
                return                  val;
            }
        }
    }

    __getInstantiablePropertiesList(){
        return                          {
            "isAjax"                        :   __convertDatasetValueToBoolean
        };
    }

    serializeJSON( options = {} ){
        let customTypesExtension        =   options[ "customTypes" ] ?? {};
        customTypesExtension            =   Object.assign( {}, customTypesExtension, this.__extendSerializerCustomTypes() ?? {} );
        return                          $( this._root ).serializeJSON( Object.assign( {}, options, { customTypes : customTypesExtension } ) );
    }

    /**
     * must return array with [ queryMode, queryBlocks, additionalBlocks, filter ]
     */
    _preparePayload( caller ){
        let serializedData              =   this.serializeJSON();
        if  ( typeof serializedData !== 'object' || !serializedData.hasOwnProperty( this._root.name ) ){
            return                      [];
        }
        if  ( typeof this?._extraPayloadBuild === 'function' ){
            serializedData              =   this._extraPayloadBuild( serializedData );
        }
        return                          [
            this.__getDefaultQueryMode(),
            [ "search" ],
            [ "headings", "thead" ],
            serializedData[ this._root.name ]
        ];
    }
    
    /**
     * @param {Event} event 
     */
    _onFormSubmit( event ){
        event.preventDefault();       
        let serializedData              =   this._preparePayload( "formSubmit" );
        if  ( !this.__enableCustomPayloadBuild() ){
            serializedData                  =   PayloadBuilder_MakePayload( 
                serializedData[ 0 ],
                serializedData[ 1 ],
                serializedData[ 2 ],
                serializedData[ 3 ],
                serializedData.length >= 4 ? serializedData[ 4 ] : null, // if we have any extraTags?
                true
            );
        }
        this.__debugMessage( "_onFormSubmit", "final payload is", serializedData );
        if  ( !this._onFormSubmitBefore( serializedData ) ){
            this.__debugMessage( '_onFormSubmit', 'onFormSubmitBefore told us to stop!' );
            return;
        };
        PayloadBuilder_SubmitPayload(
            this._root.action,
            this._root.method ?? 'POST',
            serializedData,
            ( this._onFormSubmitSuccess ).bind( this ),
            ( this._onFormSubmitFail ).bind( this ),
            ( this._onFormSubmitAlways ).bind( this )
        );
    }

    _onFormSubmitBefore( data ){
        return                          ( typeof this?._pfnOnFormSubmitBefore === 'function' ) ? this._pfnOnFormSubmitBefore( data ) : true;
    }

    /**
     * a succcessful data pass after return from server
     * @param {*} data parsed json data as object
     */
    _onFormSubmitSuccess( data ){
        this._produceWindowMessage( FINBEE_FORM_EVENT, FINBEE_FORM_STAGE_GOOD, data );
        return                          ( typeof this?._pfnOnFormSubmitSuccess === 'function' ) ? this._pfnOnFormSubmitSuccess( data ) : true;
    }

    _onFormSubmitFail( jqxhr, data, status ){
        return                          true;
    }

    _onFormSubmitAlways(){
        return                          ( typeof this?._pfnOnFormSubmitAlways === 'function' ) ? this._pfnOnFormSubmitAlways() : true;
    }
    
    /**
     * place to instantiate everything already loaded to this form before lazy loading sequence starts
     */
    _beforeLazyLoad(){
        return                          true;
    }

    _afterConstruction(){
        if  ( super._afterConstruction() ){
            if  ( this._isAjax ){
                this._getRoot().addEventListener( "submit", ( this._onFormSubmit ).bind( this ) );
            }
            this._beforeLazyLoad();
            if  ( this.__hasLazyListener() ){
                this.__lazyListener();
            } else {
                this.__debugMessage( '_afterConstruction', 'have no lazy listener to use, start sequence' );
                this.__onLoadSequenceDone( false );
            }
            return                      true;
        }
        return                          false;
    }

    __invokeSubmit(){
        this._getRoot().dispatchEvent( new Event( 'submit', { cancelable : true } ) );
    }

    //#region lazy listener logic

    /**
     * is all lazy controls are initialized?
     */
    get isLoaded(){
        return                          ( typeof this?.__lazyElements === 'undefined' );
    }

    /**
     * fires when observer have initialized specific component
     * 
     * @param {MutationRecord} mutation
     * @param {MutationObserver} observer A observer who called me
     * 
     */
    __onObserved( mutation, observer ){
        return                          true;
    }

    /**
     * everything is lazy-loaded for now or lazy-loader must not be fired at all
     * @param {Boolean} isLazySequence is called from lazy listener?
     */
    __onLoadSequenceDone( isLazySequence = false ){
        return                          true;
    }

    __lazyListener(){
        this.__lazyElements             =   new Set( this._root.querySelectorAll( LAZY_SELECTOR ) );
        if  ( this.__lazyElements.size ){
            this.__debugMessage( '__lazyListener', `need to wait for ${this.__lazyElements.size} elements` );
            this.__lazyObserver         =   new MutationObserver( ( mutations, observer ) => {
                for ( let i = 0; i < mutations.length; i++ ){
                    if  ( this.__lazyElements.has( mutations[ i ].target ) && mutations[ i ].target.classList.contains( LAZY_INITIALIZED_CLASS )  ){
                        this.__lazyElements.delete( mutations[ i ].target );
                        this.__debugMessage( '__lazyListener', `caught`, mutations[ i ].target, `${this.__lazyElements.size} left` );
                        this.__onObserved( mutations[ i ], observer );
                        if  ( !this.__lazyElements.size ){
                            observer.disconnect();
                            this.__lazyElements =   undefined;
                            this.__lazyObserver =   undefined;
                            this.__onLoadSequenceDone( true );
                            break;
                        }
                    }
                }
            } );
            this.__lazyObserver.observe( this._root, {
                subtree : true,
                attributeFilter : [ 'class' ],
                childList : true
            } );
        } else {
            this.__lazyElements         =   undefined;
            this.__debugMessage( '__lazyListener', 'have no items to wait, assuming all is loaded' );
            this.__onLoadSequenceDone( false );
        }
    }

    //#endregion

    getElementByShortName( name ){
        if  ( typeof name !== 'string' ){
            return                      undefined;
        }
        return                          this._root.elements[ this._root.name + '[' + name + ']' ];
    }

    setConnectedForm( otherInstance ){
        if  ( !( otherInstance instanceof AbstractForm ) && otherInstance ){
            throw                       new Error( this.__prepareMessage( 'setConnectedForm', 'otherInstance must be instance of AbstractForm' ) );
        }
        this._connectedForm             =   otherInstance;
        this.__debugMessage( 'setConnectedForm', 'set a connected instance', otherInstance );
        if  ( !this?._eventsBound ){
            Array.from( this._root.elements ).forEach( ( element ) => {
                console.debug( "we want to bind", element );
            } );
        }
    }

}