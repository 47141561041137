import { API_QUERY_MODE_RATING } from "../../common/payloadBuilder";
import { AbstractForm } from "../forms/AbstractForm";

export class SiteRatingClass extends AbstractForm{

    __getShowDebugData(){
        return                          false;
    }

    _preparePayload( caller ){
        return                          [
            API_QUERY_MODE_RATING,
            null,
            null,
            this.serializeJSON()[ this._root.name ]
        ];
    }

    _onFormSubmitAlways(){
        if  ( this._toast ){
            this._toast.classList.remove( "slide-right" );
            this._toast.classList.add( "slide-left" );
            setTimeout( () => {
                this._toast.classList.remove( "slide-left" );
                this._toast.classList.add( "slide-right" );
            }, 2500 );
        }
        return                          true;
    }
    
    _afterConstruction(){
        if  ( super._afterConstruction() ){
            let ratingSelector          =   this._root[ `${this._root.name}[rating]` ] ?? null;
            if  ( !ratingSelector ){
                this.__debugMessage( '_afterConstruction', 'no rating select!' );
                return                  false;
            } 
            ratingSelector.addEventListener( "change", ( event ) => {
                this.__invokeSubmit();
            } );
            this._toast                 =   this._root.querySelector( ".site-rating-block-toast" );
            return                      true;
        }
        return                          false;
    }

}